<template >
  <div>
    <div class="row">
      <div class="col-md-12">
        <v-card v-if="selected == null" style="margin-bottom: 30px">
          <v-card-title>
            Individual Job Offer
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
           @click:row="clicked"
            :headers="headers"
            :items="pending"
            :search="search"
            style="cursor: pointer;"
          ></v-data-table>
        </v-card>

        <div v-if="selected">
          <OrderInfo :idOrder="selected.order_id"></OrderInfo>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import OrderInfo from "@/view/content/OrderInfo.vue";
 
export default {
  name: "individual-job-offer-list",
  methods: {
    clicked(value) {
      this.selected = value;
      console.log(this.selected);
    },
    onSubmit(evt) {
      evt.preventDefault();
      alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  components: {
    OrderInfo,
  },
  data() {
    return {
      selected: null,
      pending: [],
      search: "",
      headers: [
        { text: "Order ID", value: "order_id" },
        { text: "Independent Contractor", value: "independent" },
        { text: "LC Value", value: "value" },
        { text: "IC Value", value: "value_final" },
      ],
    };
  },
  mounted() {
    var auth = localStorage.getItem("name");
    if(auth != null && auth != 'undefined'){
        console.log('existe')
    }else{
        console.log('nao existe')
        this.$router.push({ name: "login" });
    }
    
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vuetify", route: "alerts" },
      { title: "Tables", route: "simple-tables" },
      { title: "Data Tables" }
    ]);
 
    fetch('https://cleancity.krueltech.com/dev/api/api.php?action=readIndividual')
      .then(res => res.json())
      .then((data) => {
        this.pending = data.individual
      }).catch(console.log)
  }
};
</script>
