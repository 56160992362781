<template>
  <b-card class="mt-3" header="Info Order">
    <b-form>
      <b-row>
        <b-col>
          <b-form-group id="input-group-2" label="ID:" label-for="i1">
            <b-form-input id="i1" v-model="orderInfo.id" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Type Cleaning:" label-for="i2">
            <b-form-input id="i2" v-model="orderInfo.type" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Date Cleaning:" label-for="i3">
            <b-form-input id="i3" v-model="orderInfo.date" disabled></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="input-group-2" label="Name Worker:" label-for="i3">
            <b-form-input id="i3" v-model="orderInfo.worker_name" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Name Client:" label-for="i3">
            <b-form-input id="i3" v-model="orderInfo.client_name" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Email Client:" label-for="i3">
            <b-form-input id="i3" v-model="orderInfo.client_email" disabled></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="input-group-2" label="Phone Client:" label-for="i3">
            <b-form-input id="i3" v-model="orderInfo.client_phone" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Value:" label-for="i5">
            <b-form-input id="i5" v-model="orderInfo.value" disabled></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group id="input-group-2" label="Value Ind. Contractor:" label-for="i6">
            <b-form-input id="i6" v-model="orderInfo.value_final" disabled></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
export default {
  name: "KTCard",
  props: {
    id: String,
    idOrder: String
  },
  data() {
    return {
      orderInfo: null
    };
  },
  mounted() {
    if (this.id) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=orderInfo&id=" + this.id
      )
        .then(res => res.json())
        .then(data => {
          this.orderInfo = data.orderInfo[0];
        })
        .catch(console.log);
    }

    if (this.idOrder) {
      fetch(
        "https://cleancity.krueltech.com/dev/api/api.php?action=orderInfo&idOrder=" +
          this.idOrder
      )
        .then(res => res.json())
        .then(data => {
          this.orderInfo = data.orderInfo[0];
          console.log(this.orderInfo);
        })
        .catch(console.log);
    }
  }
};
</script>
